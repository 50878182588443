import { onBeforeUnmount, onMounted, ref } from 'vue'

export default function(target, callback, handsConnect = false) {
  const flag = ref(false)

  onMounted(() => {
    if (!handsConnect) {
      connect()
    }
  })

  onBeforeUnmount(() => {
    disconnect()
  })

  const onClick = e => {
    if (!e.composedPath().includes(target.value)) {
      callback?.()
    }
  }

  const connect = () => {
    document.addEventListener('click', onClick)
    flag.value = true
  }

  const disconnect = () => {
    document.removeEventListener('click', onClick)
    flag.value = false
  }

  return { flag, connect, disconnect }
}
