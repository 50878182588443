import { defineStore } from 'pinia'
import { ref } from 'vue'

import { APP } from '@/common/constants'
import {
  getStateMenu,
  updateStateMenu,
  getDefaultTheme,
  updateDefaultTheme,
  getTagCatalogWidth,
  updateTagCatalogWidth
} from '@/utils/userSettings.js'

const useUserSettings = defineStore('screenSettings', () => {
  const isNarrow = ref(false)
  const defaultTheme = ref(APP.THEMES.LIGHT)
  const navCatalogBlockWidth = ref()

  function init() {
    getSideMenuState()
    getDefaultThemeState()
    getTagCatalogWidthState()
  }

  function getSideMenuState() {
    isNarrow.value = getStateMenu()
  }

  function updateSideMenuState(state) {
    updateStateMenu(state)
    isNarrow.value = !!state
  }

  function getDefaultThemeState() {
    defaultTheme.value = getDefaultTheme()
  }

  function updateDefaultThemeState(theme) {
    updateDefaultTheme(theme)
    defaultTheme.value = theme
  }

  function getTagCatalogWidthState() {
    navCatalogBlockWidth.value = getTagCatalogWidth() + 'px'
  }

  function updateTagCatalogWidthState(width) {
    updateTagCatalogWidth(width)
    navCatalogBlockWidth.value = width + 'px'
  }

  return {
    isNarrow,
    defaultTheme,
    navCatalogBlockWidth,
    init,
    updateSideMenuState,
    updateDefaultThemeState,
    updateTagCatalogWidthState
  }
})

export default useUserSettings
