import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { tags } from '@/api'
import { useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)

  let tagAbortController = new AbortController()

  async function getTags(params = {}, pagination = {}) {
    let isAborted
    if (loading.value) {
      tagAbortController.abort()
      tagAbortController = new AbortController()
    }
    const data = {}
    loading.value = true
    try {
      const response = await tags.getTags(tagAbortController.signal)({
        projectId: $route.params.projectId,
        q: params.q,
        selected: params.selected,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.tags
      data.total = response.total
    } catch (e) {
      if (!e.isAborted) {
        data.error = e
        notificationsStore.showNotification('error', $t('api.tags.http.getTags.error'))
        console.error('tags load request error', e)
      } else {
        isAborted = true
      }
    } finally {
      if (!isAborted) {
        loading.value = false
      }
    }

    return data
  }

  async function getTagsByNode(params = {}, pagination = {}) {
    const data = {}
    loading.value = true
    try {
      const response = await tags.getTagsByNode({
        componentNodeId: params.componentNodeId,
        type: params.type,
        event: params.event,
        state: params.state,
        sort: params.sort,
        sortDirection: params.sortDirection,
        verbose: params.verbose,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.tags
      data.total = response.total
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tags.http.getTags.error'))
      console.error('tags load request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function getTag(tagId) {
    const data = {}
    loading.value = true
    try {
      const response = await tags.getTagInfo(tagId)
      data.response = response.tag
    } catch(e) {
      data.error = e
      console.error('tag load error', e)
      notificationsStore.showNotification('error', $t('api.tags.http.getTagInfo.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function createTags(newTags) {
    const data = {}
    loading.value = true
    try {
      const response = await tags.createTags({
        projectId: $route.params.projectId,
        tags: newTags
      })
      data.response = response.tags
      notificationsStore.showNotification('success', $t('api.tags.http.createTags.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tags.http.createTags.error'))
      console.error('tag create request error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function editTag(tagId, form) {
    const data = {}
    processing.value = true
    try {
      const response = await tags.editTag({
        tagId,
        form
      })
      data.response = response.tag
      notificationsStore.showNotification('success', $t('api.tags.http.editTag.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tags.http.editTag.error'))
      console.error('tag edit request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteTags(ids) {
    const data = {}
    processing.value = true
    try {
      await tags.deleteTags(ids)
      data.response = true
      notificationsStore.showNotification('success', $t('api.tags.http.deleteTags.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tags.http.deleteTags.error'))
      console.error('tag delete request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getTags,
    getTagsByNode,
    getTag,
    createTags,
    editTag,
    deleteTags
  }
}
