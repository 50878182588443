<template>
  <div ref="rootRef" :class="$style.root">
    <slot
      name="before"
      :fixed="beforeFixed"
      :scrollable="beforeFixed || afterFixed"
    />

    <div
      :class="[
        $style.scrollableWrap,
        {
          beforeShadow: showShadows && beforeFixed,
          afterShadow: showShadows && afterFixed
        }
      ]"
    >
      <div ref="scrollableRef" class="scrollable-area" @scroll.passive="checkVisibility">
        <slot name="default" :scrollable="beforeFixed || afterFixed" />
      </div>
    </div>

    <slot
      name="after"
      :fixed="afterFixed"
      :scrollable="beforeFixed || afterFixed"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const beforeFixed = ref(false)
const afterFixed = ref(false)
const rootRef = ref()
const scrollableRef = ref()
const resizeObs = new ResizeObserver(onResize)
let resizeTimeoutId

defineProps({
  showShadows: {
    type: Boolean,
    default: true
  }
})

onMounted(() => {
  checkVisibility()
  resizeObs.observe(rootRef.value)
})

onBeforeUnmount(() => {
  clearTimeout(resizeTimeoutId)
  resizeObs.disconnect()
})

function checkVisibility() {
  if (scrollableRef.value) {
    beforeFixed.value = scrollableRef.value.scrollTop > 0
    afterFixed.value = Math.floor((scrollableRef.value.scrollHeight || 0)
      - (scrollableRef.value.scrollTop || 0)
      - (scrollableRef.value.offsetHeight || 0)) > 0
  }
}

function onResize() {
  clearTimeout(resizeTimeoutId)
  resizeTimeoutId = setTimeout(() => {
    checkVisibility()
  })
}
</script>
<style lang="scss" module>
.root {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;

  & > * {
    flex-shrink: 0;
  }

  :global(.scrollable-area) {
    @include scrollbar();
    height: 100%;
    max-height: 100%;
    overflow: hidden scroll;
  }
}

.scrollableWrap {
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  &:global(.beforeShadow) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      height: 6px;
      box-shadow: 0 8px 12px 0 rgba(80, 80, 80, 0.16);
      z-index: $z-index-local-item;
    }
  }

  &:global(.afterShadow) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 6px;
      box-shadow: 0px -2px 12px 0px rgba(80, 80, 80, 0.16);
      z-index: $z-index-local-item;
    }
  }
}
</style>
