<template>
  <Teleport to="#modals">
    <AppModal
      v-for="modal in modalsStore.modals"
      :key="modal.id"
      v-bind="Modals[modal.template]?.options && {
        view: Modals[modal.template].options.view,
        animation: Modals[modal.template].options.animation,
        withoutHeader: Modals[modal.template].options.withoutHeader,
        closeOnOverlay: Modals[modal.template].options.closeOnOverlay
      }"
      is-open
      without-scroll-block
      @close="onClose"
    >
      <AppConfigProvider :modal="modal">
        <Component
          :is="Modals[modal.template].source"
          v-bind="modal.config"
        />
      </AppConfigProvider>
    </AppModal>
  </Teleport>
</template>

<script setup>
import { watch } from 'vue'

import { useModals } from '@/stores'
import AppModal from '@/components/modal'
import Modals from '@/modals'
import AppConfigProvider from './config-provider'

const modalsStore = useModals()

watch(() => modalsStore.visibleModal, async value => {
  if (value) {
    document.body.classList.add('modal-open')
    document.addEventListener('keyup', onEscPress)
  } else {
    document.body.classList.remove('modal-open')
    document.removeEventListener('keyup', onEscPress)
  }
})

function onClose() {
  modalsStore.closeModal()
}

function onEscPress(e) {
  if (e.key === 'Escape') {
    onClose()
  }
}
</script>
