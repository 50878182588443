<template>
  <button
    ref="buttonRef"
    :type="type"
    :class="[
      $style.root,
      {
        [`theme--${theme}`]: true,
        [`size--${size}`]: true,
        isRound: round,
        isLoading: loading,
        iconOnly: icon && iconPlacement === ICON_PLACEMENT.WHOLE
      }
    ]"
    :disabled="disabled"
  >
    <AppLoading
      v-if="loading"
      :size="loadingIconAttrs"
      :class="$style.loader"
      inherit-color
    />

    <div :class="$style.content">
      <AppIcon
        v-if="icon"
        v-bind="iconAttrs"
        :name="icon"
        :class="$style.icon"
      />

      <slot v-if="showDefaultSlot" />
    </div>
  </button>
</template>

<script setup>
import { computed } from 'vue'

import AppIcon from '@/components/icon'
import AppLoading from '@/components/loading'
import { THEMES, SIZES, ICON_PLACEMENT } from './consts'

const props = defineProps({
  type: {
    type: String,
    default: 'button'
  },
  theme: {
    type: String,
    default: THEMES.PRIMARY,
    validator(value) {
      return Object.values(THEMES).includes(value)
    }
  },
  size: {
    type: String,
    default: SIZES.M,
    validator(value) {
      return Object.values(SIZES).includes(value)
    }
  },
  disabled: Boolean,
  loading: Boolean,
  round: Boolean,
  icon: String,
  iconPlacement: {
    type: String,
    default: ICON_PLACEMENT.WHOLE,
    validator(value) {
      return Object.values(ICON_PLACEMENT).includes(value)
    }
  },
  iconSize: Number
})

const showDefaultSlot = computed(() =>
  props.icon
    ? props.iconPlacement !== ICON_PLACEMENT.WHOLE
    : true
)

const iconAttrs = computed(() => {
  if (props.iconSize) {
    return {
      height: props.iconSize,
      width: props.iconSize
    }
  }

  if (props.size === SIZES.M) {
    return {
      height: 24,
      width: 24
    }
  } else {
    return {
      height: 20,
      width: 20
    }
  }
})

const loadingIconAttrs = computed(() => {
  if (props.size === SIZES.M) {
    return 24
  } else if (props.size === SIZES.S) {
    return 20
  } else {
    return 12
  }
})
</script>

<style lang="scss" module>
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 0;
  width: 100%;
  border: 0;
  background-color: transparent;
  user-select: none;
  cursor: pointer;

  &:global(.theme--navigation-primary) {
    background-color: var(--nav-color-surface-primary);
    color: var(--nav-color-text-primary);

    &:hover {
      background-color: var(--nav-color-surface-primary-hover);
    }

    &:disabled {
      color: var(--color-btn-on-primary);
      opacity: 0.2;
    }
  }

  &:global(.theme--primary) {
    background-color: var(--color-btn-surface-primary);
    color: var(--color-btn-on-primary);

    &:hover {
      background-color: var(--color-btn-surface-primary-hover);
    }

    &:disabled {
      color: var(--color-btn-on-primary);
      opacity: 0.2;
    }
  }

  &:global(.theme--secondary) {
    background-color: var(--color-btn-surface-secondary);
    color: var(--color-btn-on-secondary);

    &:hover {
      background-color: var(--color-btn-surface-secondary-hover);
    }

    &:disabled {
      color: var(--color-btn-on-secondary-inactive);
    }
  }

  &:global(.theme--tetriary) {
    background-color: var(--color-btn-surface-tetriary);
    color: var(--color-controls-selected);

    &:disabled {
      color: var(--color-btn-on-secondary-inactive);
    }
  }

  &:global(.theme--transparent) {
    background-color: transparent;
  }

  &:global(.isRound)#{&} {
    background-color: var(--color-btn-surface-tetriary);
    color: var(--color-icon-secondary);
    border-radius: 50%;
    box-shadow: 0 2px 12px rgba(73, 73, 84, 0.16);

    &:hover {
      color: var(--color-icon-primary);
    }
  }

  &:global(.size--m) {
    height: 52px;
    min-height: 52px;
    padding: 0 32px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }

  &:global(.size--s) {
    height: 40px;
    min-height: 40px;
    padding: 0 24px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  &:global(.size--xs) {
    height: 32px;
    min-height: 32px;
    padding: 0 16px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }

  &:global(.isLoading) {
    position: relative;
    pointer-events: none;

    .content,
    .icon {
      visibility: hidden;
    }
  }

  &:global(.iconOnly) {
    aspect-ratio: 1;
    width: auto;
    padding: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.loader {
  position: absolute;
  inset: 0;
}

.icon {
  flex-shrink: 0;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
