import { getCurrentInstance, inject } from 'vue'
import { useField } from 'vee-validate'

import { VALIDATION } from '@/common/constants'

export default function (name, control) {
  const currentNode = getCurrentInstance()
  const formObserver = currentNode.provides[VALIDATION.FORM_OBSERVER_KEY] || inject(VALIDATION.FORM_OBSERVER_KEY)

  const {
    value,
    handleChange,
    errorMessage
  } = useField(name)

  function onChange(newValue, validationFlag) {
    let formattedNewValue = newValue
    if (formattedNewValue === '') {
      formattedNewValue = null
    }

    if (formObserver) {
      formObserver.onChange(
        control,
        VALIDATION.CONTROL_EVENTS.CHANGE,
        name?.value || name,
        formattedNewValue,
        value.value
      )
    }
    handleChange(formattedNewValue, validationFlag)
  }

  return {
    value,
    errorMessage,
    handleChange,
    onChange
  }
}
