<template>
  <div :class="$style.root">
    <AppLoading
      v-if="mainStore.initializing"
      :size="36"
      :class="$style.loading"
      inherit-color
    />
    <RouterView v-else />

    <AppConnectionNotification />
    <AppGlobalModal />
    <AppGlobalNotification />
  </div>
</template>

<script setup>
import { useMain } from '@/stores'
import { useScrollbar } from '@/composables/utils'
import AppGlobalModal from '@/blocks/global-modal'
import AppGlobalNotification from '@/blocks/global-notification'
import AppConnectionNotification from '@/blocks/connection-notification'
import AppLoading from '@/components/loading'

const mainStore = useMain()
useScrollbar()
</script>

<style lang="scss" module>
.root {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: getImage('bg-authorization.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.loading {
  color: var(--color-states-white-on-state);
}
</style>
