<template>
  <div
    v-if="isOpen"
    :class="[$style.overlay, `overlay--${overlay}`]"
    @click="onClose"
  >
    <div :class="$style.wrapper">
      <Suspense>
        <div
          ref="modalRef"
          :class="[
            $style.modal,
            `view--${view}`,
            `animation--${animation}`
          ]"
        >
          <div v-show="!withoutHeader" :class="$style.head">
            <AppButton
              theme="transparent"
              icon="cross"
              size="s"
              :icon-size="24"
              :class="$style.close"
              @click.stop="$emit('close')"
            />
          </div>

          <slot />
        </div>

        <template #fallback>
          <AppLoading :class="$style.loader" cover inherit-color />
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue'

import AppButton from '@/components/button'
import AppLoading from '@/components/loading'
import { VIEWS, OVERLAYS, ANIMATIONS } from './consts'

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  isOpen: Boolean,
  view: {
    type: String,
    default: VIEWS.COMMON,
    validator(value) {
      return Object.values(VIEWS).includes(value)
    }
  },
  animation: {
    type: String,
    default: ANIMATIONS.COMMON,
    validator(value) {
      return Object.values(ANIMATIONS).includes(value)
    }
  },
  overlay: {
    type: String,
    default: OVERLAYS.COMMON,
    validator(value) {
      return Object.values(OVERLAYS).includes(value)
    }
  },
  closeOnOverlay: {
    type: Boolean,
    default: true
  },
  withoutScrollBlock: Boolean,
  withoutHeader: Boolean
})

const emit = defineEmits(['close'])

const modalRef = ref()

if (!props.withoutScrollBlock) {
  document.body.classList.add('modal-open')
}

onBeforeUnmount(() => {
  if (!props.withoutScrollBlock) {
    document.body.classList.remove('modal-open')
  }
})

function onClose(e) {
  if (props.closeOnOverlay && modalRef.value && !e.composedPath().includes(modalRef.value)) {
    emit('close')
  }
}
</script>

<style lang="scss" module>
.overlay {
  @include scrollbar;
  position: fixed;
  inset: 0;
  display: flex;
  overflow: auto;
  height: 100vh;
  z-index: $z-index-modal;

  &:global(.overlay--common) {
    background-color: var(--color-overlay);
  }

  &:global(.overlay--transparent) {
    background-color: transparent;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 auto;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  &:global(.view--common) {
    padding: $modal-common-side-top-padding $modal-common-side-padding;
    border-radius: $modal-border-radius;
    background-color: var(--color-bg-modals);
  }

  &:global(.view--without-border) {
    background-color: var(--color-bg-modals);
    border-radius: $modal-border-radius;
  }

  &:global(.animation--common) {
    @include appearUp;
  }
}

.head {
  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: $z-index-local-item;
    color: var(--color-icon-secondary);
  }
}

.loader {
  color: var(--color-states-white-on-state);
}
</style>
