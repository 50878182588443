<template>
  <div :class="[$style.root, { isOffline: !isOnline }]">
    <div :class="$style.connection">
      <div :class="$style.block">
        <AppLoading v-if="!isOnline" />
        <p :class="$style.description">
          {{ $t('blocks.connectionNotification.connection') }}...
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { connection } from '@/api'
import AppLoading from '@/components/loading'

const isOnline = ref(navigator.onLine)

onMounted(() => {
  window.addEventListener('focus', onFocus)
  window.addEventListener('online', handleNetworkChange)
  window.addEventListener('offline', handleNetworkChange)
})

onBeforeUnmount(() => {
  window.removeEventListener('focus', onFocus)
  window.removeEventListener('online', handleNetworkChange)
  window.removeEventListener('offline', handleNetworkChange)
})

function onFocus() {
  if (!isOnline.value) {
    handleNetworkChange()
  }
}

const handleNetworkChange = connection.checkConnection()
  .then(() => {
    isOnline.value = true
  })
  .catch(() => {
    isOnline.value = false
  })
</script>

<style module lang="scss">
.root {
  display: none;

  &:global(.isOffline) {
    display: block;
    user-select: none;
  }
}

.connection {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-connect-notification;
}

.block {
  width: 240px;
  height: 94px;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.08);
  background: var(--color-bg-modals);
  padding: 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.description {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-primary);
}
</style>
