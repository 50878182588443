import http from '../instance'
import { SCRIPT } from '@/common/constants'

const getScriptInfo = scriptId => {
  return http.get(`/scripts/${scriptId}`)
}

const createScript = ({ title, code, projectId, scriptNodeId = null, active = null, launchTrigger = 'NONE' }) => {
  let params = {
    title,
    code,
    projectId
  }

  if (scriptNodeId) {
    params.scriptNodeId = scriptNodeId
  }

  if (active) {
    params.active = active
  }

  if (launchTrigger) {
    params.launchTrigger = launchTrigger
  }

  return http.post('/scripts', params)
}

const editScript = ({
  scriptId,
  title,
  code,
  scriptNodeId,
  active,
  launchTrigger,
  options
}) => {
  let params = {
    title,
    code,
    scriptNodeId,
    active,
    launchTrigger
  }

  if (launchTrigger === SCRIPT.TRIGGER_TYPES.SCHEDULE) {

    if (options.launchAt) {
      params.launchAt = options.launchAt
    }

    if (options.repeat) {
      params.repeat = options.repeat

      if (options.repeatType) {
        params.repeatType = options.repeatType

        if (options.repeatType === SCRIPT.REPEAT_TYPES.CHOOSEN_WEEK_DAYS) {
          if (options.repeatChoosenDays) {
            params.repeatChoosenDays = options.repeatChoosenDays
          }
        }

        if (options.repeatType === SCRIPT.REPEAT_TYPES.CUSTOM_SCHEDULE) {
          if (options.repeatInterval) {
            params.repeatInterval = options.repeatInterval
          }
        }
      }
    }
  } else if (launchTrigger === SCRIPT.TRIGGER_TYPES.TAG_UPDATE) {
    if (options.tagIds) {
      params.tagIds = options.tagIds
    }
  } else if (launchTrigger === SCRIPT.TRIGGER_TYPES.NOTIFICATION) {
    if (options.notificationIds) {
      params.notificationIds = options.notificationIds
    }
  }

  return http.post(`/scripts/${scriptId}/edit`, params)
}

const deleteScript = scriptId => {
  return http.post(`/scripts/${scriptId}/delete`)
}

const runScript = (scriptId, transactionId) => {
  return http.post(`/scripts/${scriptId}/run`, { transactionId })
}

const changeStatusScript = ({ scriptId, state }) => {
  return http.post(`/scripts/${scriptId}/set-active-state`, { state: state })
}

const importScripts = ({ projectId, files, scriptNames }) => {
  const formData = new FormData()
  formData.set('projectId', projectId)
  formData.set('scriptNames', scriptNames.join(','))
  files.forEach(f => {
    formData.append('files', f)
  })
  return http.post('/scripts/import/many', formData)
}

const exportScript = scriptId =>
  http.post(`/scripts/${scriptId}/export`, null, { responseType: 'blob' })

export default {
  getScriptInfo,
  createScript,
  runScript,
  changeStatusScript,
  editScript,
  deleteScript,
  importScripts,
  exportScript
}
