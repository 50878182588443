import { APP } from '@/common/constants'

const STATE_SIDE_MENU_KEY = 'state-side-menu'
const DEFAULT_THEME_KEY = 'default-theme'
const TAG_CATALOG_WIDTH = 'tags-catalog-width'

const getStateMenu = () => {
  const state = localStorage.getItem(STATE_SIDE_MENU_KEY)
  return state === 'true' || false
}
const updateStateMenu = value => localStorage.setItem(STATE_SIDE_MENU_KEY, value)

const getDefaultTheme = () => {
  const theme = localStorage.getItem(DEFAULT_THEME_KEY)
  if (Object.values(APP.THEMES).includes(theme)) {
    return theme
  } else {
    updateDefaultTheme(APP.THEMES.LIGHT)
    return APP.THEMES.LIGHT
  }
}
const updateDefaultTheme = value => localStorage.setItem(DEFAULT_THEME_KEY, value)

const getTagCatalogWidth = () => {
  const newWidth = localStorage.getItem(TAG_CATALOG_WIDTH)
  if (Number.isInteger(parseInt(newWidth))) {
    return newWidth
  } else {
    updateTagCatalogWidth(260)
    return 260
  }
}
const updateTagCatalogWidth = width => {
  localStorage.setItem(TAG_CATALOG_WIDTH, width)
}

export {
  getStateMenu,
  updateStateMenu,
  getDefaultTheme,
  updateDefaultTheme,
  getTagCatalogWidth,
  updateTagCatalogWidth
}
