const TYPES = {
  USER: 'USER',
  SYSTEM: 'SYSTEM'
}

const TYPES_LIST = [TYPES.SYSTEM, TYPES.USER].map(type => ({
  id: type,
  title: `domains.notification.prefixType.${type}`
}))

const TYPE_TO_PREFIX = {
  [TYPES.USER]: 'user',
  [TYPES.SYSTEM]: 'system'
}

const SEVERITIES = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ALARM: 'ALARM'
}

const SEVERITY_LIST = [
  SEVERITIES.INFO,
  SEVERITIES.WARNING,
  SEVERITIES.ALARM
].map(severity => ({
  id: severity,
  title: `domains.notification.severities.${severity}`
}))


const SEVERITY_ICONS = {
  [SEVERITIES.INFO]: 'notifications-info',
  [SEVERITIES.WARNING]: 'notifications-warning',
  [SEVERITIES.ALARM]: 'notifications-alarm'
}

const STATES = {
  NEW: 'NEW',
  READ: 'READ'
}

const STATE_LIST = [STATES.NEW, STATES.READ].map(state => ({
  id: state,
  title: `domains.notification.states.${state}`
}))

const STATE_ICONS = {
  [STATES.NEW]: 'close-message',
  [STATES.READ]: 'open-message'
}

const TRIGGERS = {
  TAG: 'TAG',
  SCRIPT: 'SCRIPT'
}

const CHANNELS = {
  EMAIL: 'EMAIL',
  TELEGRAM: 'TELEGRAM',
  WEB_PUSH: 'WEB_PUSH',
  MOBILE_PUSH: 'MOBILE_PUSH'
}

const CHANNEL_LIST = [
  CHANNELS.EMAIL,
  CHANNELS.TELEGRAM,
  CHANNELS.WEB_PUSH,
  CHANNELS.MOBILE_PUSH
].map(channel => ({
  id: channel,
  title: `domains.notification.channels.${channel}`
}))

const CHANNEL_TRANSLATES = {
  [CHANNELS.EMAIL]: `domains.notification.channels.${CHANNELS.EMAIL}`,
  [CHANNELS.TELEGRAM]: `domains.notification.channels.${CHANNELS.TELEGRAM}`,
  [CHANNELS.WEB_PUSH]: `domains.notification.channels.${CHANNELS.WEB_PUSH}`,
  [CHANNELS.MOBILE_PUSH]: `domains.notification.channels.${CHANNELS.MOBILE_PUSH}`
}

const PERIODS = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_WEEK: 'LAST_WEEK',
  PERIOD: 'PERIOD',
  NONE: 'NONE'
}

const PERIOD_LIST = [{
  id: PERIODS.TODAY,
  title: `domains.notification.period.${PERIODS.TODAY}`
}, {
  id: PERIODS.YESTERDAY,
  title: `domains.notification.period.${PERIODS.YESTERDAY}`
}, {
  id: PERIODS.LAST_WEEK,
  title: `domains.notification.period.${PERIODS.LAST_WEEK}`
}, {
  id: PERIODS.PERIOD,
  title: `domains.notification.period.${PERIODS.PERIOD}`,
  menu: 'period'
}]

const TYPES_SORT = {
  startAt: 'START_AT',
  sentAt: 'SENT_AT',
  source: 'SOURCE'
}

const PUSH_TYPES = {
  WEB: 'WEB',
  MOBILE: 'MOBILE'
}

// key is time in minutes
const TIMEZONES = {
  0: 0,
  60: 60,
  120: 120,
  180: 180,
  240: 240,
  300: 300,
  360: 360,
  420: 420,
  480: 480,
  540: 540,
  600: 600,
  660: 660,
  720: 720
}

const TIMEZONE_LIST = Object.keys(TIMEZONES).reduce((acc, time) => {
  acc.push({
    id: +time,
    title: `domains.notification.timezones.${time}`
  })
  return acc
}, [])

export default {
  TYPES,
  TYPES_LIST,
  TYPE_TO_PREFIX,
  CHANNELS,
  CHANNEL_LIST,
  CHANNEL_TRANSLATES,
  STATES,
  STATE_LIST,
  STATE_ICONS,
  SEVERITIES,
  SEVERITY_LIST,
  SEVERITY_ICONS,
  TRIGGERS,
  PERIODS,
  PERIOD_LIST,
  TYPES_SORT,
  PUSH_TYPES,
  TIMEZONES,
  TIMEZONE_LIST
}
