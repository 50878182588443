function getScaleSegments({
  min,
  max,
  startAlarm,
  endAlarm,
  startWarning,
  endWarning
}) {
  const data = {
    length: Math.abs(max - min),
    endWarning: 0,
    endAlarm: 0,
    endAlarmLength: 0,
    endWarningLength: 0,
    startWarning: 0,
    startAlarm: 0,
    startAlarmLength: 0,
    startWarningLength: 0,
    normalLength: 0
  }
  const sA = typeof startAlarm === 'number'
    ? startAlarm
    : min - 1
  const eA = typeof endAlarm === 'number'
    ? endAlarm
    : max + 1
  const sW = typeof startWarning === 'number'
    ? startWarning
    : min - 1
  const eW = typeof endWarning === 'number'
    ? endWarning
    : max + 1
  if (sA >= eA || sA >= max || eA <= min) {
    data.endAlarmLength = data.length
    return data
  }

  if (sA > min) {
    data.startAlarm = Math.abs(sA - min)
    data.startAlarmLength = data.startAlarm
  }
  if (eA < max) {
    data.endAlarm = Math.abs(eA - min)
    data.endAlarmLength = data.length - data.endAlarm
  }

  if (sW >= eW || sW >= eA || eW <= sA) {
    data.endWarning = data.startAlarm
    data.endWarningLength = data.length - data.endAlarmLength - data.startAlarmLength
    return data
  }

  if (sW > min && sW > sA) {
    data.startWarning = Math.abs(sW - min)
    data.startWarningLength = data.startWarning - data.startAlarm
  }

  if (eW < max && eW < eA) {
    data.endWarning = Math.abs(eW - min)
    data.endWarningLength = data.length - data.endWarning - data.endAlarmLength
  }

  data.normalLength = data.length
    - data.endAlarmLength
    - data.endWarningLength
    - data.startAlarmLength
    - data.startWarningLength

  return data
}

function getPercentValue(absolute, length) {
  return absolute / length
}

export {
  getScaleSegments,
  getPercentValue
}
