import { APP, NOTIFICATION } from '@/common/constants'
import { useUser } from '@/stores'
import router from '@/router'

function hasAccessToCurrentProjectRoute({ name, params }) {
  const userStore = useUser()
  const { projectId } = params
  switch (name) {
    case APP.PAGES.DASHBOARDS: {
      const access = userStore.getDashboardAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.COMPONENTS: {
      const tagAccess = userStore.getTagAccess(projectId)
      const opcAccess = userStore.getOpcServerAccess(projectId)
      const gatewayAccess = userStore.getGatewayAccess(projectId)
      return tagAccess.canRead || opcAccess.canRead || gatewayAccess.canRead
    }
    case APP.PAGES.TAGS: {
      const access = userStore.getTagAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.TAG: {
      const { tagId } = params
      const access = userStore.getTagAccess(projectId)
      return tagId !== 'new'
        ? access.canEditTag
        : access.canAddTag
    }
    case APP.PAGES.GATEWAYS: {
      const access = userStore.getGatewayAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.MQTT_GATEWAYS: {
      const access = userStore.getMqttGatewayAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.OPC_SERVERS: {
      const access = userStore.getOpcServerAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.SCRIPTS: {
      const access = userStore.getScriptAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.NOTIFICATIONS: {
      const notificationAccess = userStore.getNotificationAccess(projectId)
      const notificationTmplAccess = userStore.getNotificationTemplateAccess(projectId)
      return notificationAccess.canRead || notificationTmplAccess.canRead
    }
    case APP.PAGES.NOTIFICATION_LIST: {
      const access = userStore.getNotificationAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.NOTIFICATION_TEMPLATES: {
      const access = userStore.getNotificationTemplateAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.NOTIFICATION_TEMPLATE: {
      const { templateType, templateId } = params
      const access = userStore.getNotificationTemplateAccess(projectId)
      const isUserTemplate = NOTIFICATION.TYPE_TO_PREFIX[NOTIFICATION.TYPES.USER] === templateType
      return templateId === 'new'
        ? isUserTemplate && access.canAddUserTemplate
        : isUserTemplate
          ? access.canEditUserTemplate
          : access.canEditSystemTemplate
    }
    case APP.PAGES.HISTORY: {
      const access = userStore.getHistoryAccess(projectId)
      return access.canRead
    }
    case APP.PAGES.SETTINGS: {
      const access = userStore.getSettingsAccess(projectId)
      return access.canRead
    }
    default:
      return true
  }
}

function hasAccessToNonProjectRoute({ name, params }) {
  const userStore = useUser()
  switch (name) {
    case APP.PAGES.PROFILE_EDIT: {
      return userStore.profile.isOwner
    }
    case APP.PAGES.USERS: {
      const access = userStore.getUsersAccess()
      return access.canRead
    }
    case APP.PAGES.USER: {
      const access = userStore.getUsersAccess()
      const { userId } = params
      return userId === 'new'
        ? access.canAdd
        : access.canEdit && userId !== userStore.profile.id
    }
    case APP.PAGES.USER_GROUP_USERS:
    case APP.PAGES.USER_GROUP_PROJECTS:
    case APP.PAGES.USER_GROUP_ACCESS: {
      const access = userStore.getUserGroupsAccess()
      return access.canEdit
    }
    default:
      return true
  }
}

async function hasAccessToRoute({ name, params }) {
  const userStore = useUser()
  const { projectId } = params

  let hasAccess
  if (projectId) {
    if (await userStore.getProjectAccess(projectId)) {
      hasAccess = hasAccessToCurrentProjectRoute({ name, params })
    } else {
      hasAccess = false
    }
  } else {
    hasAccess = hasAccessToNonProjectRoute({ name, params })
  }

  return hasAccess
}

const ORDERED_PROJECT_PAGES = [
  APP.PAGES.DASHBOARDS,
  APP.PAGES.TAGS,
  APP.PAGES.GATEWAYS,
  APP.PAGES.OPC_SERVERS,
  APP.PAGES.SCRIPTS,
  APP.PAGES.NOTIFICATION_LIST,
  APP.PAGES.NOTIFICATION_TEMPLATES,
  APP.PAGES.HISTORY,
  APP.PAGES.SETTINGS
]

const toNextProjectPage = async (projectId, name) => {
  const currentPageIndex = name
    ? ORDERED_PROJECT_PAGES.findIndex(page => page === name)
    : -1
  const newPagesOrder = ORDERED_PROJECT_PAGES
    .slice(currentPageIndex + 1)
    .concat(ORDERED_PROJECT_PAGES.slice(0, currentPageIndex))
  for (const page of newPagesOrder) {
    if (await hasAccessToRoute({ name: page, params: { projectId } })) {
      if (
        page === router.currentRoute.value.name
        && projectId === router.currentRoute.value.params.projectId
      ) {
        return false
      } else {
        return { name: page, params: { projectId } }
      }
    }
  }

  return { name: APP.PAGES.ERROR, params: { type: 'access' } }
}

export {
  hasAccessToRoute,
  hasAccessToCurrentProjectRoute,
  toNextProjectPage
}
