import { addMethod, array, setLocale, string, ValidationError } from 'yup'
import { get } from 'lodash-es'

import { VALIDATION } from '@/common/constants'

setLocale({
  mixed: {
    required: 'required',
    unique: 'unique',
    notNull: 'NOT_NULL',
    notType: 'notType'
  },
  string: {
    matches: 'matches',
    min: ({ min }) => ({ key: 'minString', values: { min } }),
    max: ({ max }) => ({ key: 'maxString', values: { max } })
  },
  number: {
    required: 'required',
    min: ({ min }) => ({ key: 'minNumber', values: { min } }),
    max: ({ max }) => ({ key: 'maxNumber', values: { max } })
  },
  array: {
    min: ({ min }) => ({ key: 'minArray', values: { min } }),
    max: ({ max }) => ({ key: 'maxArray', values: { max } })
  }
})

function isValidEmail(msg = 'emailFormat') {
  return this.max(50, msg)
    .matches(/^(?!.*\.@|\.)(?!.*\.$)[a-zA-Z0-9_.+-]*[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g, msg)
}

function isValidPassword(msg = 'passwordFormat') {
  return this.min(8, msg).max(50, msg)
    .matches(/^[A-Za-z0-9\s\S]*$/g, msg)
}

function isValidEPRO24(msg = 'ePRO24Format') {
  return this.min(8, msg).max(9, msg)
    .matches(/[0-9A-G]{8}$/g, msg)
}

function symbolLimit50() {
  return this.max(VALIDATION.INPUT_LENGTH_XS, 'symbol-limit-50')
}

function symbolLimit500() {
  return this.max(VALIDATION.INPUT_LENGTH_S, 'symbol-limit-500')
}

function symbolLimit1000() {
  return this.max(VALIDATION.INPUT_LENGTH_M, 'symbol-limit-1000')
}

function firstSymbolNotNumber() {
  return this.matches(/^[^0-9].*$/g, 'first-symbol-not-number')
}

function noPunctuation() {
  return this.matches(/^[A-Za-z0-9_]+$/g, 'no-punctuation-format')
}

function onlyText() {
  return this.matches(/^[a-zA-Zа-яА-Я]+$/g, 'only-text-format')
}

function isValidBitmask16() {
  return this.matches(/^(?:[0-9]|1[0-5])$/g, 'is-valid-bitmask-16')
}

function isValidBitmask32() {
  return this.matches(/^(?:[0-9]|[12][0-9]|3[01])$/g, 'is-valid-bitmask-32')
}

addMethod(string, 'isValidEmail', isValidEmail)
addMethod(string, 'isValidPassword', isValidPassword)
addMethod(string, 'isValidEPRO24', isValidEPRO24)
addMethod(string, 'symbolLimit50', symbolLimit50)
addMethod(string, 'symbolLimit500', symbolLimit500)
addMethod(string, 'symbolLimit1000', symbolLimit1000)
addMethod(string, 'noPunctuation', noPunctuation)
addMethod(string, 'firstSymbolNotNumber', firstSymbolNotNumber)
addMethod(string, 'onlyText', onlyText)
addMethod(string, 'isValidBitmask16', isValidBitmask16)
addMethod(string, 'isValidBitmask32', isValidBitmask32)


function isUnique(keys, msg) {
  if (!Array.isArray(keys)) return false
  return this.test((newValue, options) => {
    const indexes = newValue.reduce((acc, el, index) => {
      keys.forEach(key => {
        const val = get(el, key)
        if (val) {
          if (acc.has(key)) {
            const keyMap = acc.get(key)
            acc.set(key, keyMap.set(val, (keyMap.get(val) || []).concat(index)))
          } else {
            acc.set(key, new Map([[val, [index]]]))
          }
        }
      })
      return acc
    }, new Map())
    const indexArr = Array
      .from(indexes)
      .reduce((acc, [key, map]) => {
        const keyIndexList = Array
          .from(map)
          .filter(([, list]) => list.length > 1)
          .flatMap(([, list]) => list)
        if (keyIndexList.length) {
          acc.push([key, keyIndexList])
        }

        return acc
      }, [])
    if (indexArr.length) {
      throw new ValidationError(indexArr
        .flatMap(([key, list]) => list.map(index =>
          options.createError({
            path: `${options.path}[${index}].${key}`,
            message: msg
          })
        ))
      )
    }

    return true
  })
}

addMethod(array, 'isUnique', isUnique)

