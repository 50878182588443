import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import {
  useUser,
  useProjects,
  useModals,
  useNotifications,
  useUserSettings,
  useTheme
} from '@/stores'

const INIT_TYPES = {
  ANONYMOUS: 'anonymous',
  AUTH: 'auth'
}

const useMain = defineStore('main', () => {
  const userStore = useUser()
  const projectsStore = useProjects()
  const modalsStore = useModals()
  const notificationsStore = useNotifications()
  const userSettingsStore = useUserSettings()
  const themeStore = useTheme()

  const initializing = ref()
  const initialized = ref()
  const isAuthInitialized = computed(() => initialized.value === INIT_TYPES.AUTH)
  const isAnonInitialized = computed(() => initialized.value === INIT_TYPES.ANONYMOUS)

  async function initAuth() {
    if (!initialized.value) {
      initAnon()
    }

    initializing.value = true
    if (userStore.hasSession()
      && await userStore.init()
      && await projectsStore.init()
    ) {
      initialized.value = INIT_TYPES.AUTH
    }
    initializing.value = false

    return isAuthInitialized.value
  }

  function initAnon() {
    userSettingsStore.init()
    themeStore.init()
    initialized.value = INIT_TYPES.ANONYMOUS
    return isAnonInitialized.value
  }

  function destroy() {
    userStore.destroy()
    projectsStore.destroy()
    modalsStore.destroy()
    notificationsStore.destroy()
    isAuthInitialized.value = false
  }

  return {
    isAuthInitialized,
    isAnonInitialized,
    initializing,
    initAnon,
    initAuth,
    destroy
  }
})

export default useMain
