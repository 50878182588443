import http from '../instance'

const getGateways = projectId =>
  http.get('/gateways', { params: { projectId } })

const editGateway = ({ gatewayId, name, channel, serial, description }) =>
  http.post(`/gateways/${gatewayId}/edit`, {
    name,
    channel,
    serial,
    description,
    throughput: 123
  })

const addGateway = ({ projectId, name, channel, serial, description }) =>
  http.post('/gateways', {
    projectId,
    name,
    channel,
    serial,
    description,
    throughput: 123
  })

const deleteGateway = gatewayId =>
  http.post(`/gateways/${gatewayId}/delete`)

const getGatewayClient = projectId =>
  http.get('/gateways/client', { params: { projectId } })

const addGatewayClientAuth = ({ projectId, userName, password }) =>
  http.post('/gateways/client', { projectId, userName, password })

const editGatewayClientAuth = ({ projectId, userName, password }) =>
  http.post('/gateways/client/edit', { projectId, userName, password })

export default {
  getGateways,
  editGateway,
  addGateway,
  deleteGateway,
  getGatewayClient,
  addGatewayClientAuth,
  editGatewayClientAuth
}
