import { ref, computed, watch } from 'vue'

import { getScaleSegments, getPercentValue } from '@/utils/gaugeHelpers'

/**
 * @param {Ref} chart
 * @param {Ref} currentValue
 * @param {Ref} settings
 * @param settings.min
 * @param settings.max
 * @param settings.startAlarm
 * @param settings.endAlarm
 * @param settings.alarmColor
 * @param settings.startWarning
 * @param settings.endWarning
 * @param settings.warningColor
 * @param settings.animation
 * @param settings.showBorders
 * @param settings.showValue
 * @param settings.wholeAnimationTime
 */
export default function (chart, currentValue, settings) {
  const animationSettings = {}
  let animationId
  const animatedValuePercent = ref()

  const scaleSegments = computed(() => getScaleSegments({
    min: settings.value.min,
    max: settings.value.max,
    startAlarm: settings.value.startAlarm,
    endAlarm: settings.value.endAlarm,
    startWarning: settings.value.startWarning,
    endWarning: settings.value.endWarning
  }))
  const normalizedCurrent = computed(() =>
    currentValue.value >= settings.value.max
      ? settings.value.max
      : currentValue.value <= settings.value.min
        ? settings.value.max >= 0
          ? 0
          : settings.value.max
        : Math.abs(currentValue.value - settings.value.min))

  function animateValue() {
    if (animationSettings.startTime) {
      animationId = requestAnimationFrame(renderValueFrame)
    }
  }

  const renderValueFrame = time => {
    if (!settings.value.animation
      || !settings.value.showValue
      || !settings.value.showBorders
      || animatedValuePercent.value === null) {
      animatedValuePercent.value = getPercentValue(normalizedCurrent.value, scaleSegments.value.length)
      animationSettings.startTime = 0
    } else {
      let elapsed = 1
      if (animationSettings.startTime) {
        elapsed = time - animationSettings.startTime
      } else {
        animationSettings.startTime = time
      }
      if (elapsed >= animationSettings.animationTime) {
        animatedValuePercent.value = getPercentValue(normalizedCurrent.value, scaleSegments.value.length)
        animationSettings.startTime = 0
      } else {
        animatedValuePercent.value = animationSettings.old
          + animationSettings.difference * (elapsed / animationSettings.animationTime)
      }
    }
    chart.value?.chart?.render()
  }

  const returnColorByValue = (val, normalVal = 'black') => {
    if (settings.value.showBorders) {
      if ((val <= scaleSegments.value.startAlarm && scaleSegments.value.startAlarmLength)
        || (val >= scaleSegments.value.endAlarm && scaleSegments.value.endAlarmLength)) {
        return settings.value.alarmColor
      }
      if ((val <= scaleSegments.value.startWarning && scaleSegments.value.startWarningLength)
        || (val >= scaleSegments.value.endWarning && scaleSegments.value.endWarningLength)) {
        return settings.value.warningColor
      }
    }

    return normalVal
  }

  const calcTextScales = (size, minStep) => {
    const maxScale = Math.floor(size / minStep)
    let textScales = 2
    for (let i = 3; i <= maxScale; i++) {
      if (scaleSegments.value.length % i === 0) {
        textScales = i
      }
    }
    return textScales
  }

  watch(
    () => normalizedCurrent.value,
    newValue => {
      const newPercentValue = getPercentValue(newValue, scaleSegments.value.length)
      if (animatedValuePercent.value === undefined) {
        animatedValuePercent.value = newPercentValue
        return
      }

      cancelAnimationFrame(animationId)
      if (settings.value.showValue && settings.value.showBorders) {
        if (newPercentValue !== animatedValuePercent.value) {
          animationSettings.startTime = 0
          animationSettings.old = animatedValuePercent.value
          animationSettings.difference = newPercentValue - animatedValuePercent.value
          animationSettings.animationTime = Math.abs(animationSettings.difference) * settings.value.wholeAnimationTime
          animationId = requestAnimationFrame(renderValueFrame)
        }
      } else {
        animatedValuePercent.value = newPercentValue
        chart.value?.chart?.render?.()
      }
    },
    { immediate: true }
  )

  return {
    animatedValuePercent,
    normalizedCurrent,
    scaleSegments,
    animateValue,
    returnColorByValue,
    calcTextScales
  }
}
