import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { mqttGateways } from '@/api'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)

  async function getGateways() {
    const data = {}
    loading.value = true
    try {
      data.response = await mqttGateways.getGateways($route.params.projectId)
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.getGateways.error'))
      console.error('gateway\'s request error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function addGateway({ name }) {
    const data = {}
    processing.value = true
    try {
      const response = await mqttGateways.addGateway({
        projectId: $route.params.projectId,
        name
      })
      data.response = response.mqttGateways
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.addGateway.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.addGateway.error'))
      console.error('gateway save request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function editGateway(mqttGatewayId, { name }) {
    const data = {}
    processing.value = true
    try {
      const response = await mqttGateways.editGateway({
        mqttGatewayId,
        name
      })
      data.response = response.mqttGateways
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.editGateway.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.editGateway.error'))
      console.error('gateway save request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteGateway(mqttGatewayId) {
    const data = {}
    processing.value = true
    try {
      const response = await mqttGateways.deleteGateway(mqttGatewayId)
      data.response = response.mqttGateways
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.deleteGateway.success'))
    } catch(e) {
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.deleteGateway.error'))
      console.error('delete gateway/s request error', e)
    } finally {
      processing.value = false
    }

    return data
  }


  return {
    loading,
    processing,
    getGateways,
    addGateway,
    editGateway,
    deleteGateway
  }
}
