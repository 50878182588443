import cssVars from '@/styles/variables/scss/colors.module.scss'

function getColors() {
  const styles = getComputedStyle(document.documentElement)
  return Object.keys(cssVars).reduce((acc, label) => {
    acc[label] = styles.getPropertyValue(cssVars[label])
    return acc
  }, {})
}

export {
  getColors
}
